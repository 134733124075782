<template>
  <div class="main">
    <!-- Top section -->
    <div class="main__top">
      <div
          @click="openProductShow()"
          class="add__btn mb-4"
      >
        <p>Добавить товар ➕</p>
      </div>

      <i
          @click="$router.push('/cash')"
          class="back mdi mdi-keyboard-backspace"
      ></i>
    </div>

    <h3 class="mb-2">Категории <span role="img" aria-label="categories">📂</span></h3>
    <div class="cash__categories mb-4">
      <div
          v-for="(category, category_index) in categories" :key="category_index"
          :class="{'cash__categories--item': true, 'cash__categories--active': category_id === category.id}"
          @click="getProductsByCategory(category.id)"
      >
        <p>{{ category.name }} <span role="img" aria-label="category">📋</span></p>
      </div>
    </div>

    <!-- Search bar -->
    <div class="search-bar mb-4">
      <v-text-field outlined
          v-model="search"
          class="good__search"
          type="text"
          placeholder="Поиск... 🔍"
      />
    </div>

    <!-- Products Section -->
    <div class="products">
      <div
          v-for="(product, index) in products"
          :key="index"
          class="product__item mb-4"
      >
        <p><span role="img" aria-label="product">📦 </span><b>Название:</b> {{ product.name }}</p>
        <p><span role="img" aria-label="description">📝 </span><b>Описание:</b> {{ product.description }}</p>
        <p><span role="img" aria-label="quantity">🔢 </span><b>Количество:</b> {{ product.quantity }}</p>
        <p><span role="img" aria-label="cost">💰 </span><b>Себестоимость:</b> {{ product.self_price }} тнг</p>
        <p><span role="img" aria-label="price">💵 </span><b>Цена:</b> {{ product.price }} тнг</p>
        <p class="mb-2"><span role="img" aria-label="unit">📏 </span><b>Вид измерение:</b> {{ getCountType(product.count_type) }}</p>

        <!-- Product Actions -->
        <div class="product__actions">
          <div @click="editProduct(product)" class="action-btn">
            <i class="mdi mdi-lead-pencil"></i> <span role="img" aria-label="edit">️</span>
          </div>
          <div @click="deleteProduct(product)" class="action-btn">
            <i class="mdi mdi-delete-forever"></i> <span role="img" aria-label="delete"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <v-pagination
        v-if="pageCount"
        v-model="page"
        :length="pageCount"
        :total-visible="3"
    ></v-pagination>

    <!-- Delete Product Dialog -->
    <v-dialog width="50%" v-model="product_delete">
      <v-card class="pa-5">
        <div class="product__deletion">
          <h3>Удалить товар? <span role="img" aria-label="delete confirmation">❓</span></h3>
          <div class="product__deletion__rows">
            <v-btn
                type="submit"
                class="primary mr-2"
                @click="deleteProductAction()"
            >
              <p>Да <span role="img" aria-label="yes">✅</span></p>
            </v-btn>
            <v-btn
                type="submit"
                class="red"
                @click="product_delete = false"
            >
              <p>Нет <span role="img" aria-label="no">❌</span></p>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add Category Dialog -->
    <v-dialog width="50%" v-model="category_show">
      <v-card>
        <div class="pa-5 item__column product_control">
          <p class="product_control__title">Добавить категорию <span role="img" aria-label="add category">➕📂</span></p>
          <div class="item__column product__control__label">
            <p>Название</p>
            <v-text-field outlined v-model="name" required />
          </div>
          <button
              type="submit"
              class="product__control__btn item__pointer"
              @click="saveCategory()"
          >
            <p>Сохранить <span role="img" aria-label="save">💾</span></p>
          </button>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add/Edit Product Dialog -->
    <v-dialog width="50%" v-model="product_show">
      <v-card>
        <div class="pa-5 item__column product_control">
          <p class="product_control__title mb-4">Добавить товар📦</p>

          <v-select
              :items="categories"
              item-text="name"
              item-value="id"
              label="Выберите категорию"
              v-model="category"
              outlined
          ></v-select>

          <div class="item__column product__control__label">
            <p>Название</p>
            <v-text-field outlined v-model="name" required />
          </div>

          <div class="item__column product__control__label">
            <p>Количество</p>
            <v-text-field outlined type="tel" v-model="quantity" required />
          </div>

          <div class="item__column product__control__label">
            <p>Себестоимость</p>
            <v-text-field outlined type="tel" v-model="self_price" required />
          </div>

          <div class="item__column product__control__label">
            <p>Розничная цена</p>
            <v-text-field outlined type="tel" v-model="price" required />
          </div>

          <div class="item__column product__control__label">
            <p>Тип измерение</p>
            <v-select
                :items="count_types"
                item-text="label"
                item-value="value"
                label="Тип измерение"
                v-model="count_type"
                outlined
            ></v-select>
          </div>

          <div class="item__column product__control__label">
            <p>Описание</p>
            <textarea rows="8" v-model="description" required />
          </div>

          <button
              type="submit"
              class="product__control__btn item__pointer"
              @click="product_edit ? saveEditProduct() : saveProduct()"
          >
            <p>Сохранить <span role="img" aria-label="save">💾</span></p>
          </button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {debounce} from 'lodash';

export default {
  name: "Mailing",
  components: {},
  data() {
    return {
      search: '',
      pageCount: 0,
      page: 1,
      category: '',
      categories: [],
      count_types: [
        {
          label: 'по шт',
          value: 'count'
        },
        {
          label: 'по кг',
          value: 'kg'
        },
        {
          label: 'по литр',
          value: 'litr'
        }
      ],
      product_delete: false,
      product_id: '',
      text: '',
      name: '',
      quantity: '',
      price: '',
      count_type: '',
      description: '',
      product_show: false,
      category_show: false,
      products: [],
      self_price: '',
      product_edit: false,
    }
  },
  methods: {
    openProductShow() {
      this.product_show = true
      this.product_edit = false
    },
    saveEditProduct() {
      let data = {
        name: this.name,
        quantity: this.quantity,
        price: this.price,
        count_type: this.count_type,
        description: this.description,
        category_id: this.category,
        self_price: this.self_price
      };

      this.$axios({
        method: 'put',
        url: `${this.$API_URL}product/${this.product_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.getProducts()
            this.product_show = false

            this.name = ''
            this.quantity = ''
            this.price = ''
            this.count_type = ''
            this.description = ''
            this.category_id = ''
            this.self_price = ''
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getProductsByCategory(id, page = null) {

      this.category_id = id
      if (!page)
        page = 1

      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?category_id=${id}&page=${page}&stock=stock`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('products', JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getCountType(count_type) {
      if (count_type === 'count') {
        return 'по шт';
      }
      if (count_type === 'kg') {
        return 'по кг';
      }
      if (count_type === 'litr') {
        return 'по литр';
      }
    },
    getCategories() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}category`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.categories = res.data
          })
          .catch((error) => {
            console.log(error)
          });
    },
    deleteProductAction() {

      this.$axios({
        method: 'delete',
        url: `${this.$API_URL}product/${this.product_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
          .then((res) => {
            console.log(res);
            this.product_delete = false
            this.getProductsByCategory(this.category_id)
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    deleteProduct(product) {
      this.product_delete = true
      this.product_id = product.id
    },
    editProduct(product) {
      this.product_show = true
      this.product_edit = true
      this.product_id = product.id
      this.category = product.category_id
      this.name = product.name
      this.quantity = product.quantity
      this.self_price = product.self_price
      this.price = product.price
      this.count_type = product.count_type
      this.description = product.description
    },
    saveCategory() {
      let data = {
        name: this.name,
      }
      this.$axios({
        method: 'post',
        url: `${this.$API_URL}category`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    getProducts(page = null) {

      if (!page)
        page = 1

      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?page=${page}&stock=stock`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data

            console.log("there ->>>>>",this.products)
            this.pageCount = res.data.last_page
          })
          .catch((error) => {
            console.log(error)
          });
    },
    saveProduct() {
      let data = {
        name: this.name,
        quantity: this.quantity,
        price: this.price,
        count_type: this.count_type,
        description: this.description,
        category_id: this.category,
        self_price: this.self_price
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}product`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.product_show = false
            this.name = ''
            this.quantity = ''
            this.price = ''
            this.count_type = ''
            this.description = ''
            this.category_id = ''
            this.self_price = ''

            this.getProductsByCategory(this.category_id)
          })
          .catch((error) => {
            console.log(error)
          });
    },
    debounceSearchProduct: debounce(function () {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?search=${this.search}&stock=stock`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('products', JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    }, 1000),
    searchProduct() {
      this.debounceSearchProduct()
    },
  },
  mounted() {
    this.getCategories();
  },
  watch: {
    page(newPage) {
      this.getProducts(newPage);
    },
    search(val) {
      if (val) {
        this.searchProduct();
      } else {
        this.getProductsByCategory(this.category_id);
      }
    },
  },
  computed: {},
};
</script>
<style scoped>
.main {
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.main__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add__btn {
  background-color: #007BFF;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add__btn p {
  margin-right: 10px;
}

.search-bar v-text-field outlined {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.cash__categories {
  display: flex;
  gap: 10px;
}

.cash__categories--item {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
}

.cash__categories--active {
  background-color: #007BFF;
  color: white;
}

.products {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.product__item {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.product__actions {
  display: flex;
  gap: 10px;
}

.action-btn {
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.product_control__title {
  font-size: 20px;
  font-weight: bold;
}

.product__control__label p {
  font-weight: bold;
}

.product__control__btn {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.product__control__btn.item__pointer {
  width: 100%;
}

textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
</style>